<template>
    <div class="common_content product_detail_box">
        <div class="product_flex">
            <div class="product_flex_item">
                <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                    <swiper-slide class="custom-slide cus_swiper_slide" v-for="item in detail.extra.images" :key="item">
                        <img :src="item"/>
                        {{item}}
                    </swiper-slide>
                    <!-- <swiper-slide class="custom-slide cus_swiper_slide">
                        <img src="../../assets/brand/single_img.png"/>
                    </swiper-slide> -->
                </swiper>
                <div class="swiper-pagination custon_swiper-pagination"></div>
                <!-- <div class="swiper-button-prev custom_prev_btn" slot="button-prev"></div>
                <div class="swiper-button-next custom_next_btn" slot="button-next"></div> -->
            </div>
            <div class="product_flex_item product_flex_item2">
                <ul>
                    <li>Name <span>{{detail.extra.name}}</span></li>
                    <li>number <span>{{detail.extra.number}}</span></li>
                    <li>material <span>{{material[0].name}}</span></li>
                    <li>size <span>{{size.join(',')}}</span></li>
                    <li>series <span>{{detail.extra.name}}</span></li>
                </ul>
                <div class="jump-box" @click="handleProduct">
                    <div class="flex">
                        <div class="flex-item"><img src="../../assets/brand/brand_left.png"></div>
                        <div class="flex-item" style="color:#ee660e">全部系列</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
        return {
            detail: {},
            size: [],
            material: [],
            swiperOption:{
                loop: true,
                // autoplay: true,
                clickable: true,
                
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + '0' + (index + 1) + '</span>';
                    },
                },
                on: {
                    
                }
            }
        }
    },
    created() {
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id)
            
        }
    },
    methods:{
        getMaterialDict() {
            let cond= {
                params:{
                    type: 1,//材料
                }
            };
            this.$http.get(this.$apiUrl.common.dictionary,cond).then((res) => {
                if (this.detail.extra.material) {
                    this.material = res.data.filter(item => item.id == this.detail.extra.material)
                }

            })
        },
        getSizeDict() {
            let cond= {
                params:{
                    type: 2,//尺寸
                }
            };
            this.$http.get(this.$apiUrl.common.dictionary,cond).then((res) => {
                if (this.detail.extra.size) {
                    this.size = res.data.filter(item =>  this.detail.extra.size.includes(String(item.id))).map(name=>name.name)
                }
            })
        },
        handleProduct() {
            this.$router.replace({name: 'product', query:{top_category_id: 100, category_id: this.$route.query.category_id}})
        },
        getDetail(id) {
            let cond= {
                params:{
                    id
                }
            };
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.get(this.$apiUrl.common.getNewsDetail,cond).then((res) => {
                this.detail = res.content
                this.getMaterialDict()
                this.getSizeDict()
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
    },
}
</script>

<style scoped>
.common_content{
    margin-left: 370px;
    margin-top: 4vh;
}
.product_detail_box{
    margin-right: 240px;
    margin-top: 100px;
    position: relative;
}
.product_flex{
    display: flex;
}
.product_flex_item{

}
.swiper-container{
    width: 546px;
}
.cus_swiper_slide{
    width: 546px;
    height: 730px;
}
.cus_swiper_slide img{
    width: 546px;
    height: 730px;
    box-shadow: 0px 6px 14px 0px #c9c9c9;
}
.custon_swiper-pagination{
    position: absolute;
    bottom: 0px;
    background-color: #ffffff;
    width: 516px;
    padding: 24px 0;
    margin-left: 15px;
    box-shadow: 0px 6px 14px 0px #c9c9c9;
}
div >>> .swiper-pagination-bullet-active {
    background: transparent;
    color: #ee660e;
    font-size: 18px;
}
div >>> .swiper-pagination-bullet{
        width: 40px;
    background: transparent;
    font-size: 20px;
}
.product_flex_item ul li{
    list-style: none;
    font-size: 24px;
    position: relative;
        margin-top: 62px;
}
.product_flex_item ul li span{
    list-style: none;
    color: #3e3e3e;
        position: absolute;
    left: 175px;
    width: 500px;
}
.jump-box{
    width: 716px;
    height: 110px;
    background-color: #f2f2f2;
    cursor: pointer;
}
.flex{
    display: flex;
    justify-content: space-between;
        line-height: 110px;
        padding: 0 42px;
        margin-top: 40px;
}
.product_flex_item2{
        margin-left: 100px;
}
</style>